import React, {lazy, useEffect} from "react"

import {Route, Routes} from "react-router-dom"
const Main = lazy(() => import("../components/main/Main"))

export default function BusinessPartnerView() {
	useEffect(() => {
		document.title = "B2B Partner"
	}, [])
	return(
		<Routes>
			<Route path="/" element={<Main business types={5}/>}/>
			<Route path="/:id" element={<Main business types={5}/>}/>
		</Routes>
	)
}
