import React from "react"
import "./Header.css"
import headerLogo from "../../img/header_logo.svg"
import b2bLogo from "../../img/b2b.svg"

interface IProps {
	checklistType?: number
}

export default function Header({checklistType}:IProps) {
	return (
		<header>
			{checklistType && checklistType === 3
				? <img src={b2bLogo} alt="Corplife Logo"/>
				: <img src={headerLogo} alt="Corplife Logo"/>
			}
		</header>
	)
}
