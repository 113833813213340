import React from "react"
import "./InfoScreen.css"
import {useTranslation} from "react-i18next"
import fileImage from "../../img/info_file.svg"
import deImage from "../../img/info_image_de.svg"
import enImage from "../../img/info_image_en.svg"
import i18next from "../../i18next"
import LocalInfoFirstStep from "./LocalInfoFirstStep"
import LocalInfoSecondStep from "./LocalInfoSecondStep"

interface IProps {
	step: number;
	business?: boolean;
	couponing?: boolean;
}

export default function LocalInfoScreen({step, business, couponing}:IProps) {
	const { t } = useTranslation()

	return(
		<div className="info_screen">
			<div className="info_content" >
				{step === 1
					? <h1>{couponing ?
						t("info_container.title_couponing")
						: t("info_container.title")}
					</h1>
					: null
				}
				{step === 1
					? <div className="info_content_image">
						<img style={{marginTop: "60px"}} src={fileImage} alt="file"/>
					</div>
					: null
				}
				{step === 1
					? <LocalInfoFirstStep couponing={couponing} />
					: <LocalInfoSecondStep business={business} couponing={couponing} />
				}
			</div>
			<div className="info_images" style={i18next.language === "de"
				? {backgroundImage: `url('${deImage}')`}
				: {backgroundImage: `url('${enImage}')`}
			} />
		</div>
	)
}
