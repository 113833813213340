import React, {useEffect, useRef, useState} from "react"
import "./InfoContainer.css"
import caretIcon from "../../img/angle-up-solid.svg"
import {useTranslation} from "react-i18next"
import {RootState} from "../../redux/reducers/rootReducer"
import {connect} from "react-redux"
import i18n from "../../i18next"

interface CustomProps {
	type: number
	steps?: any,
}

function InfoContainer({type, steps}:CustomProps) {

	const { t } = useTranslation()
	const width = steps.step === 0 ? 0 : (steps.step + 1) * 25

	const languageMenuRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		const handler = (event:any) => {
			if (!languageMenuRef.current?.contains(event.target as Node)) {
				setShowList(false)
			}
		}
		document.addEventListener("mousedown", handler)
		return () => {
			document.removeEventListener("mousedown", handler)
		}
	})

	const [showList, setShowList] = useState(false)
	const [selectedChoice, setSelectedChoice] = useState(i18n.language)

	const handleLanguageChange = (val:string) => {
		setSelectedChoice(val)
		switch (val) {
		case "en":
			i18n.changeLanguage("en")
			window.localStorage.setItem("lang", "en")
			break
		case "de":
			i18n.changeLanguage("de")
			window.localStorage.setItem("lang", "de")
			break
		default: i18n.changeLanguage("en")
		}
	}

	// Define name of current checklist
	const getChecklistName = () => {
		return t(`info.title_strong.${type}`)
	}

	const toggleListMenu = () => {
		setShowList(!showList)
	}

	return(
		<div className="info_container">
			<h1 id="title">
				{type === 6
					? "Deal-Konfigurator"
					:
					<>
						<strong>{getChecklistName()}</strong>
						{t("info.title")}
					</>
				}
			</h1>
			<div className="center" id="progress">

			</div>
			<div className="custom-select-wrapper" onClick={toggleListMenu}>
				<div
					ref={languageMenuRef}
					className={showList ? "custom-select open" : "custom-select"}
				>
					<div className="custom-select__trigger">
						<span>
							{selectedChoice === "de"
								? t("info.languages.de")
								: t("info.languages.en")}
						</span>
						<img
							className={showList ? "select_caret down" : "select_caret"}
							src={caretIcon}
							alt="caret"
						/>
					</div>
					<div className="custom-options" ref={languageMenuRef}>
						<span
							onClick={() => handleLanguageChange("de")}
							className={selectedChoice === "de"
								? "custom-option selected" : "custom-option"}
						>
							{t("info.languages.de")}
						</span>
						<span
							onClick={() => handleLanguageChange("en")}
							className={selectedChoice === "en"
								? "custom-option selected" : "custom-option"}
						>
							{t("info.languages.en")}
						</span>
					</div>
				</div>
			</div>

		</div>
	)
}

const mapStateToProps = (state: RootState) => {
	return {
		steps: state.steps
	}
}

export default connect(mapStateToProps, null)(InfoContainer)
