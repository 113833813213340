export const PUBLIC_KEYS = {
	// eslint-disable-next-line max-len
	encryption: "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA6Njn+fii5sOu9Tgz1y1Arsx3sbipfEBPSJ/GGH75jFTv2AetHzM8u0SHo8e9YJ1jjzQWHLUSV4C3SlBDV72rwkXbgClnY/wznNRybXNErlOXufYRmu8Wq95ueUPPVoDrD5FKVO8anofsoo5BmTv6h0X4jmN2nvWnhWcSUZ42sUyd7mSSY/8QrhtUMBMalRewQ6jClKp9xxfo9fV7mBEVNDctCCd/tP0eO78T3UQtvqVV0y5XioSmNByT5h+Fei3zaCEv/PoTlBSk/ReUj2UzUL4ZTsCBqz7P/8AaT+Uv+AUM7Q3X/OJIWYbG2IZv80Xck0DjiRmkj1o5NZW0eLPTAQIDAQAB",
	// eslint-disable-next-line max-len
	signature: "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEArH8p42TMGR4+6yHwijmM0+3sSZOM+EZSMAJwVNpG/yZJ2XHjuPm1x8/sQDgm3QkZen93aygcO3iaWfIWBcFMlkHURli5cO8toXAs+swxiQdvfnaooFC7Q26L3OhvJZiPTKPy2xDB6uKme4sDH0rd7f46CdYwvYBj0jpABGRd+SOBwUapnqqb7qLoa5GymX1qDJz4HziYeDGTPg9n8IxO9hftU/IELaLSwT7A7c3h9hxXCsoLlP3UpnCy3i0hDQiwADuptUBHqKajyCv47MsrLKASTccuYB2k9ke7qR6rKMfTBztfGPZDwt4cto/uFMQ5in2VsJQ/0b+1iqI9r8kffwIDAQAB"
}

const stringToArrayBuffer = (string: string) => {
	const encoder = new TextEncoder()
	return encoder.encode(string).buffer
}

const arrayBufferToBase64 = (buffer: ArrayBuffer) => {
	let binary = ""
	const bytes = new Uint8Array(buffer)
	const len = bytes.byteLength
	for (let i = 0; i < len; i++) {
		binary += String.fromCharCode(bytes[i])
	}
	return btoa(binary)
}

const base64ToArrayBuffer = (base64: string) => {
	const binaryString = atob(base64)
	const len = binaryString.length
	const bytes = new Uint8Array(len)
	for (let i = 0; i < len; i++) {
		bytes[i] = binaryString.charCodeAt(i)
	}
	return bytes.buffer
}

const getPublicKey = async (
	keyBuffer: ArrayBufferLike, 
	algoVariant: "OAEP" | "PSS",
): Promise<CryptoKey> => {
	return window.crypto.subtle.importKey(
		"spki",
		keyBuffer,
		{ name: `RSA-${algoVariant}`, hash: { name: "SHA-256" } },
		true,
		[algoVariant === "OAEP" ? "encrypt" : "verify"],
	)
}

const getPrivateKey = async (keyBuffer: ArrayBufferLike): Promise<CryptoKey> => {
	try {
		return window.crypto.subtle.importKey(
			"pkcs8",
			keyBuffer,
			{ name: "RSA-OAEP", hash: { name: "SHA-256" } },
			true,
			["decrypt"],
		)
	} catch (error) {
		throw new Error("getting private key failed")
	}
}

export const encryptString = async (data: string, key: string) => {
	try {
		const publicKey = await getPublicKey(base64ToArrayBuffer(key), "OAEP")
		const encryptionResult = await window.crypto.subtle.encrypt(
			{
				name: "RSA-OAEP",
			},
			publicKey,
			stringToArrayBuffer(data),
		)

		return arrayBufferToBase64(encryptionResult)
	} catch (error) {
		throw new Error(`Encryption failed: ${error}`)
	}
}

export const decryptString = async (data: string, key: string) => {
	try {
		const privateKey = await getPrivateKey(base64ToArrayBuffer(key))

		const decryptionResult = await window.crypto.subtle.decrypt(
			{
				name: "RSA-OAEP",
			},
			privateKey,
			base64ToArrayBuffer(data),
		)
		return arrayBufferToBase64(decryptionResult)
	} catch (error) {
		throw new Error(`Decryption failed: ${error}`)
	}
}

export const verifyString = async (data: string, signature: string, key: string) => {
	try {
		const publicKey = await getPublicKey(base64ToArrayBuffer(key), "PSS")

		// Verify the signature
		const isValid = await window.crypto.subtle.verify(
			{
				name: "RSA-PSS",
				saltLength: 32,
			},
			publicKey,
			base64ToArrayBuffer(signature),
			base64ToArrayBuffer(data)
		)

		return isValid
	} catch (error) {
		throw new Error(`Verification failed: ${error}`)
	}
}