import React from "react"
import "./FormikTextField.css"

interface CustomProps {
	handleChange: any,
	value: string,
	name: string
	errors: any,
	touched: any,
	onBlur: any,
	rows?: number
}

export default function FormikTextField(
	{handleChange, value, name, errors, touched, onBlur, rows}:CustomProps) {

	return(
		<div style={{position: "relative"}}>
			<textarea
				rows={rows}
				name={name}
				onChange={handleChange}
				value={value}
				className="text_form_textarea"
				onBlur={onBlur}
			/>
			{errors && touched && <p className="input_error">{errors}</p>}
		</div>
	)
}
