import {
	CHANGE_CORPORATE_VALUES,
	CHANGE_INTERN_VALUES,
	CHANGE_LOCAL_VALUES,
	CHANGE_STEP,
	CHANGE_VALUES,
	CLEAR_VALUES_MESSAGES,
	GET_VALUES,
	SEND_VALUES,
	stepsActionTypes,
	UPDATE_VALUES,
	VALUES_ERROR
} from "../types/stepsTypes"
import moment from "moment"

const initialState = {
	step: 0,
	values: [
		{
			country: ["at"],
			partner: "",
			offer: "",
			startDate: "",
			endDate: "",
			datesInitialized: false,
			firstContactRowName: "",
			firstContactRowEmail: "",
			secondContactRowName: "",
			secondContactRowEmail: "",
			thirdContactRowName: "",
			thirdContactRowEmail: ""
		},
		{
			text: "",
			image: "",
			multipleImages: false,
			uploadedImages: [],
			uploadedImagesKeys: [],
			uploadedImagesDE: [],
			uploadedImagesKeysDE: [],
			multipleFeature: false,
			featureImage: [],
			featureImageKeys: [],
			featureImageDE: [],
			featureImageKeysDE: [],
			logoImage: [],
			logoImageKeys: [],
			multipleOptions: false,
			options: "",
			optionsSub: "",
			optionsFile: [],
			optionsFileKeys: [],
			optionsDE: "",
			optionsSubDE: "",
			optionsFileDE: [],
			optionsFileKeysDE: [],
			memberCard: false,
			memberCardDE: false,
			links: "",
			multipleLinks: false,
			linksSub: "",
			linksSubDE: "",
			tags: "",
			tagsSub: "",
			videos: "",
			customVideo: ""
		},
		{
			title: "",
			titleDE: "",
			description: "",
			descriptionDE: "",
			text: "",
			textDE: "",
			highlights: "",
			highlightsDE: "",
			conditions: "",
			conditionsDE: "",
			additionalConditions: "",
			additionalConditionsDE: "",
			merchant: "",
			merchantDE: ""
		}
	],
	localValues: [
		{
			partner: "",
			offer: "",
			firstName: "",
			email: ""
		},
		{
			text: "",
			image: "",
			multipleImages: false,
			uploadedImages: [],
			uploadedImagesKeys: [],
			uploadedImagesDE: [],
			uploadedImagesKeysDE: [],
			multipleFeature: false,
			featureImage: [],
			featureImageKeys: [],
			featureImageDE: [],
			featureImageKeysDE: [],
			logoImage: [],
			logoImageKeys: [],
			multipleOptions: false,
			options: "",
			optionsSub: "",
			optionsFile: [],
			optionsFileKeys: [],
			optionsDE: "",
			optionsSubDE: "",
			optionsFileDE: [],
			optionsFileKeysDE: [],
			links: "",
			multipleLinks: false,
			linksSub: "",
			linksSubDE: "",
			tags: "",
			tagsSub: "",
			videos: "",
			customVideo: ""
		},
		{
			title: "",
			titleDE: "",
			description: "",
			descriptionDE: "",
			text: "",
			textDE: "",
			highlights: "",
			highlightsDE: "",
			conditions: "",
			conditionsDE: "",
			additionalConditions: "",
			additionalConditionsDE: "",
			merchant: "",
			merchantDE: ""
		}
	],
	internalValues: {
		firstName: "",
		lastName: "",
		email: "",
		merchant: "",
		benefits: "",
		date: moment().format("YYYY-MM-DD"),
		images: [],
		imagesKeys: [],
		voucher: [],
		voucherKeys: []
	},
	corporateValues: {
		company: "",
		employees: 0,
		workers: 0,
		mainLocation: "",
		mainLocationEmployee: 0,
		states: "",
		mainContact: {
			firstName: "",
			lastName: "",
			email: ""
		},
		itContact: {
			firstName: "",
			lastName: "",
			email: ""
		},
		companyLogo: "",
		emails: "",
		secureCode: "",
		category: "",
		age: "",
		women: "",
		men: "",
		divers: ""
	},
	message: "",
	valuesFromDB: {},
	id: ""
}

export const stepsReducer = (
	state = initialState,
	action: stepsActionTypes) => {
	switch (action.type) {
	case CHANGE_STEP:
		return {
			...state,
			step: action.payload
		}
	case CHANGE_VALUES:
		return {
			...state,
			values: action.payload
		}
	case CHANGE_LOCAL_VALUES:
		return {
			...state,
			localValues: action.payload
		}
	case CHANGE_INTERN_VALUES:
		return {
			...state,
			internalValues: action.payload
		}
	case CHANGE_CORPORATE_VALUES:
		return {
			...state,
			corporateValues: action.payload
		}
	case SEND_VALUES:
		return {
			...state,
			message: "success",
			id: action.payload
		}
	case GET_VALUES:
		return {
			...state,
			valuesFromDB: action.payload
		}
	case UPDATE_VALUES:
		return {
			...state,
			message: action.payload
		}
	case VALUES_ERROR:
		return {
			...state,
			message: action.payload
		}
	case CLEAR_VALUES_MESSAGES:
		return {
			...state,
			message: ""
		}
	default: return state
	}
}
