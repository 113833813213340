import React, {lazy, useEffect} from "react"

import {Route, Routes} from "react-router-dom"
import LocalMain from "../components/local/LocalMain"
const Main = lazy(() => import("../components/main/Main"))

interface ILocalView {
	couponing?: boolean
}

export default function LocalView(props: ILocalView) {
	useEffect(() => {
		document.title = "Local Check List"
	}, [])
	return(
		<Routes>
			<Route path="/" element={<LocalMain couponing={props.couponing} />}/>
			<Route path="/:id" element={<LocalMain couponing={props.couponing} />}/>
		</Routes>
	)
}
