import React from "react"
import "./Button.css"

interface CustomProps {
	text: string;
	submit?: boolean;
	disabled: boolean;
	secondary?: boolean;
	onClick?: () => void;
	style?: any;
}

export default function Button({text, submit, disabled, secondary, onClick, style}:CustomProps) {

	const handleClick = () => {
		if (onClick) {
			onClick()
		}
	}

	return (
		<button
			onClick={handleClick}
			className={secondary ? "button secondary" : "button"}
			disabled={disabled}
			type={submit ? "submit" : "button"}
			style={style ? style : {}}
		>
			{text}
		</button>
	)
}
