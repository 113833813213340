import React, {useEffect, useState} from "react"
import {RootState} from "../redux/reducers/rootReducer"
import {connect, useDispatch} from "react-redux"
import Button from "../components/button/Button"
import {useTranslation} from "react-i18next"
import {
	changeStep,
	changeValues,
	getValues,
	sendValues
} from "../redux/actions/stepsActions"
import GeneralInfo from "../components/corporateForm/GeneralInfo"
import {useLocation} from "react-router-dom"
import SuccessContainer from "../components/mainComponents/successContainer/successContainer"
import Footer from "../components/footer/Footer"

interface IProps {
	steps?: any;
	sendFormState?: any;
	storedValues?: any;
	serverMessages?: any;
	props?: any;
}

function CorporateView({steps, sendFormState, storedValues, serverMessages, props}:IProps) {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const location = useLocation()

	const [updateId, setUpdateId] = useState("")

	const [valuesAssigned, setValuesAssigned] = useState(false)
	const [valuesFromDb, setValuesFromDb] = useState(false)

	useEffect(() => {
		const locationPath = location.pathname.split("/")
		if (locationPath.length && locationPath.length === 3) {
			setValuesFromDb(true)
			dispatch(getValues(locationPath[2]))
			setUpdateId(locationPath[2])
		}
	}, [])

	useEffect(() => {
		if (
			storedValues
			&& Object.keys(storedValues).length
			&& !valuesAssigned) {
			const savedValues = [
				{
					company: storedValues.company,
					employees: storedValues.questions[0].nestedText || 0,
					workers: storedValues.questions[10].nestedText || 0,
					mainLocation: storedValues.questions[7].nestedText || "",
					mainLocationEmployee: storedValues.questions[8].nestedText || 0,
					states: storedValues.questions[9].nestedText || "",
					mainContact: {
						firstName: storedValues.questions[4].nestedText || "",
						lastName: storedValues.questions[5].nestedText || "",
						email: storedValues.questions[6].nestedText || ""
					},
					itContact: {
						firstName: storedValues.questions[1].nestedText || "",
						lastName: storedValues.questions[2].nestedText || "",
						email: storedValues.questions[3].nestedText || ""
					}
				},
				{
					companyLogo: storedValues.questions[11].images || [],
					emails: storedValues.questions[12].nestedText || [],
					secureCode: storedValues.questions[13].nestedText || []
				},
				{
					category: storedValues.questions[14].nestedText || [],
					age: storedValues.questions[15].nestedText || [],
					women: storedValues.questions[16].nestedText || [],
					men: storedValues.questions[17].nestedText || [],
					divers: storedValues.questions[18].nestedText || []
				}
			]
			dispatch(changeValues(savedValues, 1))
		}
	}, [steps])

	const [buttonLoading, setButtonLoading] = useState(false)

	const [buttonDisabled, setButtonDisabled] = useState(false)

	const renderElement = () => {
		switch (steps.step) {
		case 0:
			return <GeneralInfo
				setButtonDisabled={(state:boolean) => setButtonDisabled(state)}
			/>
		default :
			return <p>Error</p>
		}
	}

	const handleForwardButton = () => {
		const values = {
			type: 2,
			company: steps.corporateValues.company,
			status: 0,
			salesType: 4,
			questions: [
				{
					questionIndex: 0,
					nestedText: steps.corporateValues.employees
				},
				{
					questionIndex: 1,
					nestedText: steps.corporateValues.itContact.firstName
				},
				{
					questionIndex: 2,
					nestedText: steps.corporateValues.itContact.lastName
				},
				{
					questionIndex: 3,
					nestedText: steps.corporateValues.itContact.email
				},
				{
					questionIndex: 4,
					nestedText: steps.corporateValues.mainContact.firstName
				},
				{
					questionIndex: 5,
					nestedText: steps.corporateValues.mainContact.lastName
				},
				{
					questionIndex: 6,
					nestedText: steps.corporateValues.mainContact.email
				},
				{
					questionIndex: 7,
					nestedText: steps.corporateValues.mainLocation
				},
				{
					questionIndex: 8,
					nestedText: steps.corporateValues.mainLocationEmployee
				},
				{
					questionIndex: 9,
					nestedText: steps.corporateValues.states
				},
				{
					questionIndex: 10,
					nestedText: steps.corporateValues.workers
				},
				{
					questionIndex: 13,
					images: steps.corporateValues.companyLogo,
				},
				{
					questionIndex: 14,
					nestedText: steps.corporateValues.emails,
				},
				{
					questionIndex: 15,
					nestedText: steps.corporateValues.secureCode,
				},
				{
					questionIndex: 16,
					nestedText: steps.corporateValues.category,
				},
				{
					questionIndex: 17,
					nestedText: steps.corporateValues.age,
				},
				{
					questionIndex: 18,
					nestedText: steps.corporateValues.women,
				},
				{
					questionIndex: 19,
					nestedText: steps.corporateValues.men,
				},
				{
					questionIndex: 20,
					nestedText: steps.corporateValues.divers,
				},
			]
		}
		dispatch(changeStep(steps.step + 1))
		setButtonLoading(true)
		dispatch(sendValues(values))
	}

	const handleBackButton = () => {
		dispatch(changeStep(steps.step - 1))
	}

	return (
		<main style={{paddingTop: "1px"}}>
			{steps.step === 0
				? <div
					className="main_header"
					style={{marginBottom: "10px", paddingTop: "20px"}}
				>
					<div />
					<Button
						onClick={() => handleForwardButton()}
						text={t("main_form.header.btn_send")}
						disabled={buttonDisabled}
					/>
				</div>
				:
				<div className="main_header">
					<div>
						<h3 className="title_h3" >{t("main_form.header.title")}</h3>
						<p className="main_form_header_text">
							{t("main_form.header.text_2")}
						</p>
					</div>
					{/*{steps.step !== 1
						?
						<div className="header_btn_container">
							<Button
								text={t("main_form.header.btn_back")}
								disabled={buttonLoading}
								secondary={true}
								onClick={() => handleBackButton()}
							/>
							{buttonLoading
								?
								<div className="button_placeholder">
									<BeatLoader size="7px" color="white" />
								</div>
								:
								<Button
									onClick={() => handleForwardButton()}
									text={t("main_form.header.btn_send")}
									disabled={buttonDisabled}
								/>
							}
						</div>
						: null
					}*/}
				</div>
			}

			<div className={steps.step === 1
			|| steps.step === 0 ? "main_container full" : "main_container"}>

				{steps.step === 1
					? <SuccessContainer type={2} update={updateId} />
					: null
				}

				{steps.step !== 1
					?
					<>
						{renderElement()}
					</>
					: null
				}
			</div>
			<Footer type={2} />
		</main>
	)
}

const mapStateToProps = (state: RootState) => {
	return {
		steps: state.steps,
		sendFormState: state.steps.message,
		storedValues: state.steps.valuesFromDB,
		serverMessages: state.steps.message
	}
}

export default connect(mapStateToProps, null)(CorporateView)
