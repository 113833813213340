import React from "react"
import {useTranslation} from "react-i18next"
import {motion} from "framer-motion"

interface ILocalInfoFirstStep {
	couponing?: boolean;
}

export default function LocalInfoFirstStep(props: ILocalInfoFirstStep) {
	const { t } = useTranslation()

	return(
		<motion.div
			animate={{ y: 0, opacity: 1 }}
			initial={{ y: "100%", opacity: 0 }}
			transition={{
				delay: 0.3,
				default: { duration: 0.4 },
			}}
			className="info_step"
			style={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				height: "60%"
			}}
		>
			<h2 style={{textAlign: "center", transform: "translateY(-60px)"}}>
				{t("info_container.step_1.title")}
			</h2>
			<div className="info_step_title">
				<div />
				<h3>{t("info_container.step_1.title_online")}</h3>
			</div>
			<div className="info_step_sub_title">
				<div />
				<p>{t("info_container.step_1.local_sub_title_online_1")}</p>
			</div>
			{!props.couponing &&
				<div className="info_step_sub_title">
					<div />
					<p>{t("info_container.step_1.local_sub_title_online_2")}</p>
				</div>
			}
			<div className="info_step_title">
				<div />
				<h3>{t("info_container.step_1.title_present")}</h3>
			</div>

			<div className="info_step_sub_title">
				<div />
				<p>
					{props.couponing
						? t("info_container.step_1.sub_title_present_2_couponing")
						: t("info_container.step_1.sub_title_present_2")}
				</p>
			</div>
		</motion.div>
	)
}
