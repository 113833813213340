import {TOOLTIP_IN_HOVER} from "../types/tooltipElementTypes"

// Change tooltip
export function setTooltip(tooltip:string) {
	return {
		type: TOOLTIP_IN_HOVER,
		payload: tooltip
	}
}


