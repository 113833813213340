import React from "react"
import "./LoadingPage.css"
import LoadingText from "../loading/LoadingText"

export default function LoadingPage() {
	return(
		<div className="loading_grid_container">
			<div className="loading_sec-1 shadow loading_box">
				<LoadingText minWidth="200px" maxWidth="400px" height="30px" />
				<LoadingText height="15px" padding="15px 0 0 0" />
				<LoadingText height="15px" padding="15px 0 0 0" />
				<div className="row">
					<LoadingText
						height="70px"
						width="40px"
						padding="20px 0 0 0"
						borderRadius="10px" />
					<LoadingText
						height="20px" width="200px" padding="25px 0 0 10px" />
					<LoadingText
						height="40px"
						maxWidth="130px" padding="10px 0 0 0" />
				</div>
				<div className="row">
					<LoadingText
						height="70px"
						width="40px"
						padding="20px 0 0 0"
						borderRadius="10px" />
					<LoadingText
						height="20px" width="200px" padding="25px 0 0 10px" />
					<LoadingText
						height="40px"
						maxWidth="130px" padding="10px 0 0 0" />
				</div>
				<LoadingText height="15px" padding="15px 0 0 0" />
			</div>
			<div className="loading_sec-2 shadow loading_box">
				<div>
					<LoadingText height="30px" maxWidth="180px" />
					<LoadingText
						height="40px"
						maxWidth="130px" padding="10px 0 0 0" />
					<LoadingText
						height="20px" maxWidth="90%" padding="100px 0 0 0"/>
					<LoadingText
						height="20px" maxWidth="90%" padding="10px 0 0 0" />
					<LoadingText
						height="20px" maxWidth="90%" padding="10px 0 0 0" />

				</div>
			</div>
			<div className="loading_sec-3 shadow loading_box">
				<div className="row">
					<LoadingText
						height="50px" width="50px" borderRadius="10px" />
					<LoadingText
						height="25px" width="200px" padding="5px 0 0 10px" />
				</div>
				<div className="row">
					<LoadingText
						height="40px"
						width="40px"
						borderRadius="10px"
						padding="50px 0 0 0" />
					<LoadingText
						height="20px" width="200px" padding="55px 0 0 10px" />
				</div>
				<div className="row">
					<LoadingText
						height="70px"
						width="40px"
						padding="20px 0 0 0"
						borderRadius="10px" />
					<LoadingText
						height="20px" width="200px" padding="25px 0 0 10px" />
					<LoadingText
						height="40px"
						maxWidth="130px" padding="10px 0 0 0" />
				</div>
				<LoadingText
					height="20px" width="200px" padding="25px 0 0 10px" />
				<LoadingText
					height="40px"
					maxWidth="130px" padding="10px 0 0 0" />
				<LoadingText
					height="20px" width="200px" padding="25px 0 0 10px" />
				<LoadingText
					height="40px"
					maxWidth="130px" padding="10px 0 0 0" />
			</div>
			<div className="loading_sec-4 shadow loading_box" />
			<div className="loading_sec-5 shadow loading_box" />
			<div className="loading_sec-6 shadow loading_box" />
			<div className="loading_sec-7 shadow loading_box" />
		</div>
	)
}
