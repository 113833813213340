import { customHistory } from "../App"

export function errorHandler(status: number) {
	if (status === 404) {
		customHistory.push("/error")
		return true
	} else if (status === 500) {
		customHistory.push("/server-error")
		return true
	} else {
		return false
	}
}
