export let url: string

if (process.env.REACT_APP_ENV === "production") {
	// production server
	url = "https://api.corplife.at/v0/"
} else if (process.env.REACT_APP_ENV === "development") {
	// staging server
	url = "https://api-dev.corplife.at/v0/"
} else if (process.env.REACT_APP_ENV === "local") {
	// staging server
	url = "http://localhost:8000/v0/"
} else if (process.env.REACT_APP_ENV === "staging") {
	// staging server
	url = "https://api-stag.corplife.at/v0/"
} else {
	// development server
	url = "https://api.corplife.at/v0/"
}
