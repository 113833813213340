import {combineReducers} from "redux"
import {stepsReducer} from "./stepsReducer"
import {tooltipReducer} from "./tooltipReducer"

export const rootReducer = combineReducers({
	steps: stepsReducer,
	tooltip: tooltipReducer
})

export type RootState = ReturnType<typeof rootReducer>
