import React, {useEffect, useState} from "react"
import {RootState} from "../../redux/reducers/rootReducer"
import {connect, useDispatch} from "react-redux"
import {useTranslation} from "react-i18next"
import {useFormik} from "formik"
import * as Yup from "yup"
import FormikInput from "../inputs/FormikInput"
import FormikTextField from "../inputs/formikTextField/FormikTextField"
import {setTooltip} from "../../redux/actions/tooltipActions"
import {changeValues} from "../../redux/actions/stepsActions"
import uploadIcon from "../../img/upload_icon.png"
import {toast} from "react-toastify"
import CustomToastContainer from "../toast/Toast"
import {url} from "../../core/endpoints"
import axios from "axios"
import {urls} from "../../api/urls"

interface IProps {
	steps?: any;
	sendFormState?: any;
	storedValues?: any;
	serverMessages?: any;
	setButtonDisabled: (state: boolean) => void
}

function GeneralInfo({
	steps,
	sendFormState,
	storedValues,
	serverMessages,
	setButtonDisabled
}:IProps) {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const [isAssigned, setIsAssigned] = useState(false)
	const [subErrors, setSubErrors] = useState<any>({})

	const formik = useFormik<any>({
		initialValues: {
			company: "",
			employees: 0,
			workers: 0,
			mainLocation: "",
			mainLocationEmployee: 0,
			states: "",
			mainContact: {
				firstName: "",
				lastName: "",
				email: ""
			},
			itContact: {
				firstName: "",
				lastName: "",
				email: ""
			},
			companyLogo: "",
			emails: "",
			secureCode: "",
			category: "",
			age: "",
			women: "",
			men: "",
			divers: ""
		},
		validationSchema: Yup.object().shape({
			company: Yup.string().required(t("warnings.validation.required")),
			employees: Yup.number().when("workers", {
				is: (workers:number) => !workers || workers === 0,
				then: Yup.number().required(t("warnings.validation.required"))
					.min(1, t("warnings.validation.one_or_other", {
						one: t("corporate_form.general.employeeNumber"),
						two: t("corporate_form.general.workersNumber")
					})),
				otherwise: Yup.number()
			}),
			workers: Yup.number().when("employees", {
				is: (employees:number) => !employees || employees === 0,
				then: Yup.number().required(t("warnings.validation.required"))
					.min(1, t("warnings.validation.one_or_other")),
				otherwise: Yup.number()
			}),
			mainLocation: Yup.string().required(t("warnings.validation.required")),
			mainLocationEmployee: Yup.number().required(t("warnings.validation.required"))
				.min(1, t("warnings.validation.employee_min")),
			states: Yup.string().required(t("warnings.validation.required")),
			mainContact: Yup.object({
				firstName: Yup.string().required(t("warnings.validation.required")),
				lastName: Yup.string().required(t("warnings.validation.required")),
				email: Yup.string().required(t("warnings.validation.required")),
			}),
			companyLogo: Yup.string().required(t("warnings.validation.required")),
			emails: Yup.string().when("secureCode", {
				is: (secureCode:string) => !secureCode || secureCode.length === 0,
				then: Yup.string().required(t("warnings.validation.required"))
					.min(1, t("warnings.validation.one_or_other", {
						one: t("corporate_form.PlatformInfo.emails"),
						two: t("corporate_form.PlatformInfo.secureCode")
					})),
				otherwise: Yup.string()
			}),
			secureCode: Yup.string().when("emails", {
				is: (emails:string) => !emails || emails.length === 0,
				then: Yup.string().required(t("warnings.validation.required"))
					.min(1, t("warnings.validation.one_or_other", {
						one: t("corporate_form.PlatformInfo.emails"),
						two: t("corporate_form.PlatformInfo.secureCode")
					})),
				otherwise: Yup.string()
			})
		}, [["employees", "workers"], ["emails", "secureCode"]]),
		onSubmit: (e:any) => {
			// Block submit
		},
	})

	useEffect(() => {
		dispatch(changeValues(formik.values, 1))
		if (formik.isValid && formik.dirty) {
			setButtonDisabled(false)
		} else {
			setButtonDisabled(true)
		}
	}, [formik.values])

	useEffect(() => {
		if (steps
			&& steps.corporateValues
			&& steps.corporateValues.length
			&& !isAssigned
			&& steps.corporateValues[0].company
		) {
			setIsAssigned(true)
			Object.keys(steps.corporateValues[0]).map((item:string) => {
				formik.setFieldValue(item, steps.corporateValues[0][item], true)
			})
		}
	}, [steps])

	const getImageKey = async (files:any) => {
		const formData = new FormData()
		const options = {headers: {"Content-Type": "multipart/form-data"}}
		Array.from(files).forEach((image:any) => {
			formData.append("files", image)
		})
		formData.append("files", files)
		const fullUrl = `${url}${urls.files.multiple}`
		try {
			const uploadImage = await axios.post(fullUrl, formData, options)
			uploadImage.data.forEach((image:any) => {
				formik.setFieldValue("companyLogo", image.key)
			})
		} catch (e: any) {
			console.log(e)
		}
	}

	const validateForm = () => {
		let valid = true
		const errors:any = {}
		if (!formik.values.companyLogo.length) {
			valid = false
			errors.companyLogo = t("warnings.validation.images_upload")
		}
		if (formik.isValid && formik.dirty) {
			setButtonDisabled(!valid)
		} else {
			setButtonDisabled(true)
		}
		setSubErrors(errors)
	}

	const fileSelectedHandler = async (name: string, e: any) => {
		const validFileExtensions = ["image/png", "application/pdf", "image/svg+xml"]
		const files = e.target.files
		let showTypeError = false
		let showImageNameLengthError = false
		let fileKey = ""
		Object.values(files).map((file: any) => {
			console.log(file.type)
			if (validFileExtensions.includes(file.type)) {
				if (file.name.length <= 200) {
					fileKey = file
				} else {
					showImageNameLengthError = true
				}
			} else {
				showTypeError = true
			}
		})
		if (showTypeError) {
			toast.error(
				<CustomToastContainer
					message={t("warnings.image_upload.file_type_img")}
					status={3}/>, {
					autoClose: 2000,
				})
		} else if (showImageNameLengthError) {
			toast.error(
				<CustomToastContainer
					message={t("warnings.image_upload.file_name_length")}
					status={3}/>, {
					autoClose: 2000,
				})
		} else {
			await Promise.resolve()
			validateForm()
			getImageKey(fileKey)
		}
	}


	return(
		<div className="corporate_view">
			<div>
				<h3>{t("corporate_form.title")}</h3>
				<FormikInput
					errors={formik.errors.company}
					touched={formik.touched.company}
					htmlFor="company"
					name="company"
					value={formik.values.company}
					disabled={false}
					handleChange={formik.handleChange}
					label={t("corporate_form.general.company")}
					onBlur={formik.handleBlur}
				/>

				<h3 style={{marginBottom: 0}}>
					{t("corporate_form.general.employeeTitle")}
				</h3>
				<p style={{marginTop: 0}} className="sub_label">
					{t("corporate_form.general.employeeTitleDescription")}
				</p>

				<div
					style={{marginTop: "20px"}}
					className="simple_input">
					<label
						className="simple_label"
						htmlFor="employee"
					>
						{t("corporate_form.general.employeeNumber")}
					</label>
					<input
						type="number"
						className="input"
						name="employees"
						value={formik.values.employees}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
					/>
					{formik.errors.employees && formik.touched.employees &&
					<p className="input_error">{
						formik.errors.employees
					}</p>}
				</div>

				<div
					style={{marginTop: "20px"}}
					className="simple_input">
					<label
						className="simple_label"
						htmlFor="employee"
					>
						{t("corporate_form.general.workersNumber")}
					</label>
					<input
						type="number"
						className="input"
						name="workers"
						value={formik.values.workers}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
					/>
					{formik.errors.workers && formik.touched.workers &&
					<p className="input_error">{
						formik.errors.workers
					}</p>}
				</div>

				<h3 style={{marginBottom: 0}}>
					{t("corporate_form.general.mainLocation")}
				</h3>
				<p style={{marginTop: 0}} className="sub_label">
					{t("corporate_form.general.mainLocationDescription")}
				</p>

				<FormikInput
					style={{marginTop: "20px"}}
					errors={formik.errors.mainLocation}
					touched={formik.touched.mainLocation}
					htmlFor="mainLocation"
					name="mainLocation"
					value={formik.values.mainLocation}
					disabled={false}
					handleChange={formik.handleChange}
					label={t("corporate_form.general.mainLocation")}
					onBlur={formik.handleBlur}
				/>

				<div
					style={{marginTop: "20px"}}
					className="simple_input">
					<label
						className="simple_label"
						htmlFor="employee"
					>
						{t("corporate_form.general.mainLocationNumber")}
					</label>
					<input
						type="number"
						className="input"
						name="mainLocationEmployee"
						onBlur={formik.handleBlur}
						value={formik.values.mainLocationEmployee}
						onChange={formik.handleChange}
					/>
					{formik.errors.mainLocationEmployee && formik.touched.mainLocationEmployee &&
					<p className="input_error">{
						formik.errors.mainLocationEmployee
					}</p>}
				</div>

				<div
					style={{marginBottom: "30px", position: "relative"}}
					data-testid="description_title"
				>
					<p className="simple_label">
						{t("corporate_form.general.states")}
					</p>
					<p className="sub_label" style={{margin: 0}}>
						{t("corporate_form.general.statesDescription")}
					</p>
					<textarea
						name="states"
						rows={5}
						onChange={formik.handleChange}
						value={formik.values.states}
						className="textarea_corporate"
						onBlur={formik.handleBlur}
					/>
					{formik.errors.states && formik.touched.states &&
					<p className="input_error">{
						formik.errors.states
					}</p>}
				</div>

				<h2>
					{t("corporate_form.general.mainContact.title")}
				</h2>
				<div className="contact_grid">
					<FormikInput
						style={{marginTop: "20px"}}
						errors={(formik.errors.mainContact as any)?.firstName}
						touched={(formik.touched.mainContact as any)?.firstName}
						htmlFor="mainContact.firstName"
						name="mainContact.firstName"
						value={formik.values.mainContact?.firstName}
						disabled={false}
						handleChange={formik.handleChange}
						label={t("corporate_form.general.mainContact.firstName")}
						onBlur={formik.handleBlur}
					/>
					<FormikInput
						style={{marginTop: "20px"}}
						errors={(formik.errors.mainContact as any)?.lastName}
						touched={(formik.touched.mainContact as any)?.lastName}
						htmlFor="mainContact.lastName"
						name="mainContact.lastName"
						value={formik.values.mainContact?.lastName}
						disabled={false}
						handleChange={formik.handleChange}
						label={t("corporate_form.general.mainContact.lastName")}
						onBlur={formik.handleBlur}
					/>
				</div>
				<FormikInput
					style={{marginTop: "20px"}}
					errors={(formik.errors.mainContact as any)?.email}
					touched={(formik.touched.mainContact as any)?.email}
					htmlFor="mainContact.email"
					name="mainContact.email"
					value={formik.values.mainContact?.email}
					disabled={false}
					handleChange={formik.handleChange}
					label={t("corporate_form.general.mainContact.email")}
					onBlur={formik.handleBlur}
				/>

				<h2>
					{t("corporate_form.general.itContact.title")}
				</h2>
				<p className="sub_label" style={{margin: 0}}>
					{t("corporate_form.general.itContact.description")}
				</p>
				<div className="contact_grid">
					<FormikInput
						style={{marginTop: "20px"}}
						errors={(formik.errors.itContact as any)?.firstName}
						touched={(formik.touched.itContact as any)?.firstName}
						htmlFor="itContact.firstName"
						name="itContact.firstName"
						value={formik.values.itContact?.firstName}
						disabled={false}
						handleChange={formik.handleChange}
						label={t("corporate_form.general.itContact.firstName")}
						onBlur={formik.handleBlur}
					/>
					<FormikInput
						style={{marginTop: "20px"}}
						errors={(formik.errors.itContact as any)?.lastName}
						touched={(formik.touched.itContact as any)?.lastName}
						htmlFor="itContact.lastName"
						name="itContact.lastName"
						value={formik.values.itContact?.lastName}
						disabled={false}
						handleChange={formik.handleChange}
						label={t("corporate_form.general.itContact.lastName")}
						onBlur={formik.handleBlur}
					/>
				</div>
				<FormikInput
					style={{marginTop: "20px"}}
					errors={(formik.errors.itContact as any)?.email}
					touched={(formik.touched.itContact as any)?.email}
					htmlFor="itContact.email"
					name="itContact.email"
					value={formik.values.itContact?.email}
					disabled={false}
					handleChange={formik.handleChange}
					label={t("corporate_form.general.itContact.email")}
					onBlur={formik.handleBlur}
				/>
			</div>

			<div>
				<h3>
					{t("corporate_form.PlatformInfo.title")}
				</h3>
				<div style={{position: "relative", height: "60px"}}>
					<p className="simple_label">
						{t("corporate_form.PlatformInfo.logo")}
					</p>
					<p className="sub_label" style={{margin: 0}}>
						{t("corporate_form.PlatformInfo.logoDescription")}
					</p>
					<div className="image_btn" style={{
						position: "relative",
						maxWidth: "200px",
						right: 0,
						marginTop: "15px",
						marginBottom: "15px"
					}}>
						<img src={uploadIcon} alt="upload"/>
						<input
							type="file"
							id="images_btn"
							hidden
							multiple
							onChange={(e:any) => fileSelectedHandler("images", e)}
							accept="application/pdf,image/svg+xml,.png"
						/>
						<label htmlFor="images_btn">
							{formik.values.companyLogo.length
								? t("main_form.form.images_btn_filled",
									{files: 1})
								: t("main_form.form.images_btn_empty")
							}
						</label>
					</div>
				</div>

				<div
					style={{marginBottom: "30px", marginTop: "60px"}}
					data-testid="description_title"
				>
					<p className="simple_label">
						{t("corporate_form.PlatformInfo.emails")}
					</p>
					<p className="sub_label" style={{margin: 0}}>
						{t("corporate_form.PlatformInfo.emailsDescription")}
					</p>
					<textarea
						name="emails"
						rows={15}
						onChange={formik.handleChange}
						value={formik.values.emails}
						className="textarea_corporate"
					/>
				</div>

				<FormikInput
					style={{marginTop: "20px"}}
					errors={formik.errors.secureCode}
					touched={formik.errors.secureCode}
					htmlFor="secureCode"
					name="secureCode"
					value={formik.values.secureCode}
					disabled={false}
					handleChange={formik.handleChange}
					label={t("corporate_form.PlatformInfo.secureCode")}
					onBlur={formik.handleBlur}
					description={t("corporate_form.PlatformInfo.secureCodeDescription")}
				/>

				<div style={{
					width: "100%",
					height: "1px",
					backgroundColor: "#cecece",
					marginTop: "20px",
					marginBottom: "20px"
				}} />

				<h3>
					{t("corporate_form.content_optimization.title")}
				</h3>

				<div
					style={{marginBottom: "30px", position: "relative"}}
					data-testid="description_title"
				>
					<p className="simple_label">
						{t("corporate_form.content_optimization.category")}
					</p>
					<p className="sub_label" style={{margin: 0}}>
						{t("corporate_form.content_optimization.categoryDescription")}
					</p>
					<textarea
						name="category"
						rows={15}
						onChange={formik.handleChange}
						value={formik.values.category}
						onBlur={formik.handleBlur}
						className="textarea_corporate"
					/>
					{formik.errors.category && formik.touched.category &&
					<p className="input_error">{
						formik.errors.category
					}</p>}
				</div>

				<p className="simple_label">
					{t("corporate_form.content_optimization.demographic")}
				</p>
				<p className="sub_label" style={{margin: 0}}>
					{t("corporate_form.content_optimization.demographicDescription")}
				</p>

				<FormikInput
					style={{marginTop: "20px"}}
					errors={formik.errors.age}
					touched={formik.errors.age}
					htmlFor="age"
					name="age"
					value={formik.values.age}
					disabled={false}
					handleChange={formik.handleChange}
					label={t("corporate_form.content_optimization.age")}
					onBlur={formik.handleBlur}
				/>

				<FormikInput
					style={{marginTop: "20px"}}
					errors={formik.errors.women}
					touched={formik.errors.women}
					htmlFor="women"
					name="women"
					value={formik.values.women}
					disabled={false}
					handleChange={formik.handleChange}
					label={t("corporate_form.content_optimization.women")}
					onBlur={formik.handleBlur}
				/>

				<FormikInput
					style={{marginTop: "20px"}}
					errors={formik.errors.men}
					touched={formik.errors.men}
					htmlFor="men"
					name="men"
					value={formik.values.men}
					disabled={false}
					handleChange={formik.handleChange}
					label={t("corporate_form.content_optimization.men")}
					onBlur={formik.handleBlur}
				/>

				<FormikInput
					style={{marginTop: "20px"}}
					errors={formik.errors.divers}
					touched={formik.errors.divers}
					htmlFor="divers"
					name="divers"
					value={formik.values.divers}
					disabled={false}
					handleChange={formik.handleChange}
					label={t("corporate_form.content_optimization.divers")}
					onBlur={formik.handleBlur}
				/>
			</div>

		</div>
	)
}

const mapStateToProps = (state: RootState) => {
	return {
		steps: state.steps,
		sendFormState: state.steps.message,
		storedValues: state.steps.valuesFromDB,
		serverMessages: state.steps.message
	}
}

export default connect(mapStateToProps, null)(GeneralInfo)
