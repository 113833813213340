import React, {lazy, useEffect, useState} from "react"

import "../src/components/datePicker/DatePicker.css"
import "react-date-range/dist/styles.css"
import "react-date-range/dist/theme/default.css"

import { createBrowserHistory } from "history"
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom"

import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"

import "./App.css"
import {ToastContainer} from "react-toastify"
import Header from "./components/header/Header"
import InfoContainer from "./components/infoContainer/InfoContainer"
import CorporateView from "./views/CorporateView"
import BusinessPartnerView from "./views/BusinessBenefitsView"
import LocalView from "./views/LocalView"

const PartnerView = lazy(() => import("./views/partnerView"))
const ErrorView = lazy(() => import("./views/errorView"))
const InternBenefitsView = lazy(() => import("./views/internBenefitsView"))
const Main = lazy(() => import("./components/main/Main"))

export const customHistory = createBrowserHistory()

function App() {
	const history = createBrowserHistory()

	useEffect(() => {
		document.title = "corplife Checklist"
	}, [])

	const [checklistType, setCheckListType] = useState(0)

	useEffect(() => {
		if (history.location.pathname === "/werbepartner") {
			if(!history.location.search.includes("?multi=true")){
				//redirect to new page
				history.push("/werbepartner?multi=true")
			}
			setCheckListType(0)
		} else if (history.location.pathname === "/interne-benefits") {
			setCheckListType(1)
		} else if (history.location.pathname === "/b2b") {
			setCheckListType(3)
		} else if (history.location.pathname === "/local") {
			setCheckListType(4)
		} else if (history.location.pathname === "/couponing") {
			setCheckListType(6)
		} else {
			setCheckListType(2)
		}
	}, [history])

	// INITIALIZE FIREBASE TOOLS
	// FOR ANALYTICS AND PERFORMANCE BASE
	useEffect(() => {
		if (process.env.REACT_APP_ENV === "production") {
			// Initialize Sentry
			Sentry.init({
				dsn: "https://efb0a033ebbe435fbea9c1680fc27ee9@o538122.ingest.sentry.io/6012174",
				integrations: [new Integrations.BrowserTracing()],
				tracesSampleRate: 1.0,
			})
		}
	}, [])

	return (
		<div className="App">
			<ToastContainer style={{zIndex: 9999}} />
			<Header checklistType={checklistType} />
			<InfoContainer type={checklistType} />
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<Navigate to="/werbepartner" />} />
					<Route path="/werbepartner" element={<PartnerView />}/>
					<Route path="/werbepartner/:id" element={<PartnerView />}/>
					<Route path="/werbepartner-1" element={<Main types={2} />}/>
					<Route path="/werbepartner-1/:id" element={<Main types={2} />}/>
					<Route path="/werbepartner-2" element={<Main types={3}/>}/>
					<Route path="/werbepartner-2/:id" element={<Main types={3}/>}/>
					<Route path="/interne-benefits" element={<InternBenefitsView />}/>
					<Route path="/interne-benefits/:id" element={<InternBenefitsView />}/>
					<Route path="/b2b" element={<BusinessPartnerView />}/>
					<Route path="/b2b/:id" element={<BusinessPartnerView />}/>
					<Route path="/corporate" element={<CorporateView />}/>
					<Route path="/corporate/:id" element={<CorporateView />}/>
					<Route path="/couponing" element={<LocalView couponing={true} />}/>
					<Route path="/couponing/:id" element={<LocalView couponing={true} />}/>
					<Route path="/local" element={<LocalView />}/>
					<Route path="/local/:id" element={<LocalView />}/>
					<Route path="/server-error"  element={<ErrorView server />}/>
					<Route path="/error" element={<ErrorView />} />
					<Route element={ErrorView}/>
				</Routes>
			</BrowserRouter>
		</div>
	)
}

export default App
