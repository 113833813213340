export const ibanLengths = {
	"AT": 20,
	"BE": 16,
	"BG": 22,
	"HR": 21,
	"CY": 28,
	"CZ": 24,
	"DK": 18,
	"EE": 20,
	"FI": 18,
	"FR": 27,
	"DE": 22,
	"GR": 27,
	"HU": 28,
	"IE": 22,
	"IT": 27,
	"LV": 21,
	"LT": 20,
	"LU": 20,
	"MT": 21,
	"PL": 28,
	"PT": 25,
	"RO": 24,
	"SK": 24,
	"SI": 19,
	"ES": 24,
	"SE": 24,
	"NL": 18,
	"UA": 29
}



export const ibanIsValid = (iban: string): {
		valid: false, 
		details: "country" | "length" | "shape"
	} | {
		valid: true
	} => {
	// Step 0: Remove spaces and convert to uppercase
	iban = iban.replace(/\s+/g, "").toUpperCase()


	const countryCode: string = iban.slice(0, 2)

	// Step 1: Check if it is the correct country

	if(!(Object.keys(ibanLengths).includes(countryCode))) return {
		valid: false,
		details: "country"
	}

	// Step 2: Check if the IBAN is the correct length

	const expectedLength = ibanLengths[countryCode as keyof typeof ibanLengths]
	if (typeof expectedLength === "undefined" || iban.length !== expectedLength) return {
		valid: false,
		details: "length"
	}

	// Step 3: Rearrange the IBAN
	const rearrangedIban = iban.slice(4) + iban.slice(0, 4)

	// Step 4: Convert letters to numbers
	const numericIban = rearrangedIban.split("").map(char => {
		const code = char.charCodeAt(0)
		// A-Z -> 10-35; 0-9 -> 0-9
		return (code >= 65 && code <= 90) ? (code - 55) : (code - 48)
	}).join("")

	// Step 5: Perform the checksum calculation
	const mod97 = (number: string) => {
		let remainder = ""
		for (let i = 0; i < number.length; i++) {
			remainder = (remainder + number.charAt(i)).slice(-6) // Keep last 6 digits
			remainder = (BigInt(remainder) % BigInt(97)).toString() // Perform mod 97
		}
		return remainder === "1" // Valid IBAN should have remainder 1
	}

	const isNumericallyValid = mod97(numericIban)

	if(!isNumericallyValid) return {
		valid: false,
		details: "shape"
	}

	return {
		valid: true,
	}
}