import React, {Suspense} from "react"
import ReactDOM from "react-dom"
import {Provider} from "react-redux"
import Store from "./redux/store"
import App from "./App"
import "antd/dist/antd.css"

import "./index.css"
import "./i18next"
import LoadingPage from "./components/loadingPage/LoadingPage"

ReactDOM.render(
	<Provider store={Store}>
		<React.StrictMode>
			<Suspense fallback={<LoadingPage />}>
				<App />
			</Suspense>
		</React.StrictMode>
	</Provider>,
	document.getElementById("root")
)
