import {
	TOOLTIP_IN_HOVER,
	tooltipActionTypes
} from "../types/tooltipElementTypes"

const initialState = {
	element: ""
}

export const tooltipReducer = (
	state = initialState,
	action:tooltipActionTypes) => {
	switch (action.type) {
	case TOOLTIP_IN_HOVER:
		return {
			...state,
			element: action.payload
		}
	default: return state
	}
}
