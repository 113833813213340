import React from "react"
import successIcon from "../../img/success_msg_icon.svg"
import warningIcon from "../../img/warning_msg_icon.svg"
import errorIcon from "../../img/error_msg_icon.svg"

interface CustomProps {
	message: any,
	status: number,
}

export default function CustomToastContainer({message, status}: CustomProps) {
	let icon = ""
	switch (status) {
	case 1:
		icon = successIcon
		break
	case 2:
		icon = warningIcon
		break
	case 3:
		icon = errorIcon
		break
	}

	return(
		<div className="row">
			<img
				style={{marginLeft:"0.5rem", marginRight:"1rem"}}
				src={icon}
				alt={status.toString()}
			/>
			<p>{message}</p>
		</div>
	)
}
